<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Vendorcategory'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/vendorcategory`" title="Vendor Category"
                :columns="columns" routerpath="/master/account/addvendorcategory" :formOptions="formOptions"
                :edit="edit" :add="add" :canDelete="canDelete" :viewFields="viewFields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios'
import { BRow, BCol } from 'bootstrap-vue'

import GoodTableColumnSearch from '../../../../components/GoodTableColumnSearch.vue'
import moment from 'moment'
import TabPosition from '../../../../components/TabView.vue'
const baseApi = process.env.VUE_APP_APIENDPOINT

export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
    BRow,
    BCol
  },
  data() {
    return {
      // tabs: [
      //   { name: 'Ledger', route: '/master/accounting/ledger', icon: 'WindIcon' },
      //   { name: 'AccountGroup', route: '/master/accounting/accountgroup', icon: 'UsersIcon' },
      //   { name: 'BankAccountMaster', route: '/master/accounting/bankaccount', icon: 'GridIcon' },
      //   { name: 'BankStatement', route: '/master/accounting/bankstatement', icon: 'FileTextIcon' },
      //   { name: 'Vendor', route: '/master/accounting/vendor', icon: 'SidebarIcon' },
      //   { name: 'Vendortype', route: '/master/accounting/vendortype', icon: 'TypeIcon' },
      //   { name: 'Vendorcategory', route: '/master/accounting/vendorcategory', icon: 'OctagonIcon' },
      //   { name: 'Broker', route: '/master/accounting/broker', icon: 'UserIcon' },
      //   { name: 'Farmer', route: '/master/accounting/farmer', icon: 'FramerIcon' },
      //   { name: 'PurchaseBuyer', route: '/master/accounting/purchasebuyer', icon: 'TriangleIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.params.id ? 'Edit Vendor Category' : 'Add Vendor Category'
          }`,
        submitRouterPath: '/master/accounting/vendorcategory',
        getEditValue: `${baseApi}/getvendorcategoryById`,
        gridForm: true,
        usersDropdown: true,
        method: 'post',
        action: 'add',
        url: `${baseApi}/vendorcategory`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          // {
          //   name: "nextfollowupdate",
          //   value: `${moment(new Date()).format("DD/MM/yyyy")}`,
          //   type: "hidden",
          // },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Vendor Category',
            name: 'vendorcategory',
            value: '',
            type: 'text',
            placeholder: 'Enter Vendor Category',
            class: 'col-md-12',
            required: true
          },
          {
            label: 'Vendor Type',
            name: 'vendortype',
            url: 'getvendortype',
            value: '',
            type: 'dropdown',
            dropdownname: 'vendortype',
            placeholder: 'None',
            responseValue: 'id',
            options: [],
            class: 'col-md-12',
            required: true
          }
        ]
      },
      viewFields: [
        {
          label: 'Vendor Category',
          field: 'vendorcategory',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Vendor Type',
          field: 'vendortype',
          type: 'dropdown',
          responseValue: 'name',
          class: 'col-md-4'
        },
      ],
      columns: [
        {
          label: 'Vendor Category',
          field: 'vendorcategory',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Vendor Category'
          }
        },
        {
          label: 'Vendor Type',
          field: 'vendortype',
          type: 'dropdown',
          url: 'vendortype',
          responseValue: 'vendortype',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Vendor Type'
          }
        },
        {
          label: 'Enter By',
          field: 'enterby',
          type: 'dropdown',
          url: 'getUsers',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Enter Date',
          field: 'created_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        {
          label: 'Modified By',
          field: 'modifiedby',
          type: 'dropdown',
          url: 'getUsers',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Modified Date',
          field: 'updated_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      edit: '',
      add: '',
      canDelete: ''
    }
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem('UserRights'))
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      userData.map((item) => {
        if (item.modulename == 'Vendor Category') {
          this.edit = item.edit
          this.add = item.add
          this.canDelete = item.delete
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push('/')
          }
        }
      })
    }
  },
  beforeMount() {
    const accessToken = localStorage.getItem('accessToken')
    const baseApi = process.env.VUE_APP_APIENDPOINT
    this.formOptions.inputFields.map(async (item) => {
      if (item.type == 'dropdown' && item.url) {
        const requestOptionsUsers = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          url: `${baseApi}/${item.url}`
        }

        await axios(requestOptionsUsers)
          .then((response) => {
            response.data.data.data
              ? (item.options = response.data.data.data)
              : (item.options = response.data.data)
          })
          .catch((error) => console.log(error, 'error'))
      }
    })
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
